.schedule_button_opaque {
    opacity: 0.5 !important;
}

.schedule_button_not_opaque {
    opacity: 1 !important;
}

.schedule_button {
    background-color: #3f51b5 !important;
    color: #FFFFFF !important;
}