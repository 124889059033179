.myTittleText {
  font-size: 24px !important;
}

.myInputPaddingSelect {
  height: 60px !important;
  font-size: 14px !important;
  width: 200px !important;
}

.myInputPaddingNumber {
  height: 60px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  font-size: 32px !important;
  width: 150px !important;
}

.divPadding {
  padding-top: 20px !important;
}

.btn-circle.btn-xl {
  width: 150px;
  height: 40px;
  padding: 10px 16px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.myTableBorder {
  border-bottom: 0.5px solid #cecaca !important;
  padding-left: 0px !important;
}

.myTableBorderFixplanningHeader {
  border-bottom: 0.5px solid #cecaca !important;
  padding-left: 0px !important;
  padding-right: 25px !important;
}

.myTableBorderFixplanningHeaderCD {
  width: 40px !important;
  border-bottom: 0.5px solid #cecaca !important;
  padding-left: 0px !important;
  padding-right: 20px !important;
}

.myTableBorderFixplanning {
  border-bottom: 0.5px solid #cecaca !important;
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.myTableBorderLess {
  border: none !important;
  padding-right: 0px !important;
  text-align: right !important;
  width: 48px !important;
  padding-left: 2px !important;
}

.myTableBorderLeft {
  margin-left: 5px !important;
}

.tableWidthSmall {
  width: 30px !important;
}

.tableWidthBig {
  width: 70px !important;
}

.tableWidthVerySmall {
  width: 5px !important;
}

.myTableLeft {
  padding-left: 0px !important;
  text-align: left !important;
}

.myTableLeftCD {
  width: 40px !important;
  padding-left: 0px !important;
  text-align: left !important;
}

.myTalbeSize {
  width: 700px !important;
}

.myDisplayF {
  display: none;
}

.myDisplayT {
  display: block;
}

.myTableCenter {
  text-align: center !important;
}

.myTableRight {
  text-align: right !important;
  margin-top: -3%;
}

#optimizerCar {
  width: 200px;
}

#MyGrid {
  display: flex;
  justify-content: center;
}

#optimizerZone {
  width: 200px;
}

#flagSelect {
  width: 200px;
}

#storeSelect {
  width: 200px;
}

#glsSelect {
  width: 200px;
}

.select {
  width: 180px !important;
}

.select-dialog {
  max-width: 100% !important;
}

.formSpace {
  width: 220px !important;
}

.myTitle {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 5px;
  padding-left: 10px;
}

.btn-circle2.btn-xl2 {
  width: 150px;
  height: 35px;
  padding: 5px 8px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.btn-circle3.btn-xl3 {
  width: 200px;
  height: 40px;
  padding: 10px 16px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.btn-circle4.btn-xl4 {
  width: 220px;
  height: 40px;
  padding: 10px 16px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.btn-circle5.btn-xl5 {
  width: 150px;
  height: 35px;
  padding: 5px 8px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: rgb(176, 16, 16) !important;
  color: white !important;
  margin-right: 5px;
}

.btn-circle6.btn-xl6 {
  width: 100px;
  height: 35px;
  padding: 5px 8px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.btn-circle7.btn-xl7 {
  width: 250px;
  height: 35px;
  padding: 5px 8px;
  border-radius: 35px;
  line-height: 1.33;
  background-color: lightskyblue !important;
  color: black;
}

.inline {
  display: inherit !important;
}

.GridSpace {
  margin-bottom: 40px !important;
}

.myLabel {
  height: 40px;
  width: 200px;
  font-size: 25px;
}

.myLabelLong {
  height: 40px;
  width: 800px;
  font-size: 25px;
}

.myLabelAdditional {
  height: 40px;
  width: 450px;
  font-size: 25px;
}

.myLabelSelect {
  height: 40px;
  width: 200px;
  font-size: 18px;
}

.labelFormItem {
  height: 40px;
  font-size: 18px;
}

.marginBotton {
  height: 38px !important;
  font-size: 32px !important;
  vertical-align: bottom !important;
}

.itemAlign {
  align-items: center !important;
}

.myTableBorderLessFlag {
  border: none !important;
  padding-right: 0px !important;
  text-align: left !important;
  width: 200px !important;
  padding-left: 0px !important;
}

.myTableBorderLessAdditional {
  border: none !important;
  padding-right: 0px !important;
  text-align: left !important;
  padding-left: 0px !important;
}

.myTableBorderLessFix {
  border: none !important;
  padding-right: 0px !important;
  text-align: left !important;
  width: 120px !important;
  padding-left: 0px !important;
}

.widthCD {
  width: 100px !important;
}

.widthStore {
  width: 100px !important;
}

.widthStoreType {
  width: 80px !important;
}

.widthButtonStore {
  width: 100px !important;
}

.marginBloqueo {
  margin-top: 30px;
}

.inputButton {
  display: none !important;
}

.mySnackBarSuccess {
  background-color: rgb(67, 160, 71) !important;
  margin-bottom: 10px !important;
}

.mySnackBarWarning {
  background-color: rgb(255, 160, 0) !important;
  margin-bottom: 10px !important;
}

.mySnackBarError {
  background-color: rgb(211, 47, 47) !important;
  margin-bottom: 10px !important;
}

.colorSpan {
  color: black !important;
  display: flex !important;
  line-height: 24px !important;
}

.Select-menu-outer {
  max-height: 100px !important;
}

.widthHeader {
  width: 100%;
}

.imgHeader {
  margin-top: 5px;
  float: right;
}

.displayHeader {
  display: inline;
}

.hHeader {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
}

.deleteDepartures .deleteDrivers {
  background-color: white;
  padding-left: 20px !important;
}

.DialogTitleColor {
  background-color: darkgray;
  color: white !important;
}

.GridPadding {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

#labelSap{
  font-size: 22pt;
}