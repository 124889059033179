.loading-container {
	animation: rotate 6s linear infinite;
	height: 50px;
	width: 100px;
	transform-origin: bottom center;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 100px;
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

.circle {
	animation: grow 4s linear infinite;
	background-color: cyan;
	border-radius: 50%;
	display: inline-block;
	margin: -3px;
	padding: 3px;
	height: 40px;
	width: 40px;
	transform: scale(0);
}

.circle:nth-of-type(2) {
	animation-delay: 1.25s;
	background-color: slateblue;
}
.circle:nth-of-type(3) {
	animation-delay: 1.95s;
	background-color: slateblue;
}

@keyframes grow {
	50% {
		transform: scale(1.75);
	}
}

.msg{
	text-align: center;
	font-weight: 420;
	margin-bottom: 0;
	margin-top: 0;
}
.msg1{
	text-align: center;
	margin-top: 0;

}