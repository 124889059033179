:root {
  --calendar-header-color: #8f0029;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.black_check_mark{
  color: black;
  font-size: 120%;
}

.calendar_header{
  color: var(--calendar-header-color);
}

.calendar_header:hover{
  text-decoration: underline;
  cursor: default;
}

.center_text {
  text-align: center;
}

.container-box{
  position: absolute;
  background-color: #3F51B5;
  padding: 2px;
  margin-top: 25%;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  justify-content: center;
  align-content: center;
  display: flex;
  top: 0;
}
